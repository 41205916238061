import React, { useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import api from '../_api';
import State from '../_components/State';
import Transition from '../_components/Transition';
import { SurveyContext } from '../_contexts/Survey';

export default function SurveyDetail() {
  const { id } = useParams();
  const {
    survey,
    setSurvey,
    states,
    setStates,
    addState,
    transitions,
    setTransitions,
  } = useContext(SurveyContext);

  useEffect(() => {
    const fetchSurvey = async () => {
      const response = await api(`/surveys/${id}`, { method: 'GET' });

      if (response) {
        setSurvey(response.data.survey);
        setStates(response.data.survey.states);
        setTransitions(response.data.survey.transitions);
      }
    };

    fetchSurvey();
  }, [id]);

  if (!survey) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{survey.name}</h1>
      <p>
        <b>Channel:</b> {survey.channel}
      </p>
      <p>
        <b>Created at:</b> {survey.created_at}
      </p>
      <table className="table table-bordered table-sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone number</th>
          </tr>
        </thead>
        <tbody>
          {survey?.participants &&
            survey.participants.map(({ id, name, phone_number }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>{phone_number}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <Link to={`/surveys/${id}/flow_editor`}>Flow editor</Link>
    </div>
  );
}
