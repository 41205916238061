import React from "react";
import { scaleLinear } from "d3-scale";
import {
  Annotation,
  ComposableMap,
  Geographies,
  Geography,
  Graticule
} from "react-simple-maps";

const colorScale = scaleLinear()
  .domain([0, 4000])
  .range(["#ffedea", "#ff5233"]);

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

export default function({ data }) {

  let countries = {};

  data.forEach(entry => {
    if (!entry)
      return;
    countries[entry.countryInfo.iso3] = entry;
  });

  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-20.0, -3.0, 0],
        scale: 460
      }}
    >
      <Graticule stroke="#eaeaec" />
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => {
            
            const name = geo.properties.ISO_A3;
            const d = countries.hasOwnProperty(name) ? countries[name] : null;

            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={d ? colorScale(d.cases) : "#ccc"}
                stroke="#eee"
              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
}
