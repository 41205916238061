import React, { useContext } from 'react';
import styled from 'styled-components';
import { UiContext } from 'contexts/Ui';

const Title = styled.span`
  line-height: 1.3em;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
  font-size: ${({ isRibbon }) => (isRibbon ? '0.65em' : '0.75em')};
  font-weight: 800;
  color: #444;
  padding: 0.2em 1.2em 0;
`;

export default function ({ children, ...props }) {
  const { isMobile, sidebarExpanded } = useContext(UiContext);
  return (
    <li>
      <Title isRibbon={isMobile || !sidebarExpanded} {...props}>
        {children}
      </Title>
    </li>
  );
}
