import React, { useState, useEffect, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import LocalStorage from 'helpers/LocalStorage';

export const SessionContext = createContext({});

export function SessionContextProvider({ children }) {
  const [session, setSession] = useState(LocalStorage.getSession());
//  const history = useHistory();

  useEffect(() => {
    LocalStorage.setSession(session);
  }, [session]);

  const login = session => {
    session.project = {
      id: 1,
      name: 'Covid-19'
    };
    setSession(session);
//    history.goBack();
  };

  const logout = () => {
    setSession(null);
  };

  const selectProject = project => {
    if (session) {
      setSession({ ...session, project });
    }
  };

  const sessionContext = {
    login,
    logout,
    isAuthenticated: () => !!session?.token,
    hasProject: () => !!session?.project,
    selectProject,
    project: session?.project,
    user: session?.user,
    token: session?.token,
  };

  return (
    <SessionContext.Provider value={sessionContext}>
      {children}
    </SessionContext.Provider>
  );
}
