import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import Error from 'components/Common/Error';
import { useApi } from 'hooks/Api'

function RegisterForm({ watch, register, errors, disabled, onSubmit }) {
  return (
    <Form onSubmit={onSubmit}>
      <fieldset disabled={disabled}>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            isInvalid={errors.name}
            type="text"
            name="name" 
            placeholder="Name"
            ref={register({
              required: 'This field is required',
            })} 
          />
          {errors.name && (
            <Form.Control.Feedback type="invalid">
              {errors.name.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            isInvalid={errors.email}
            type="text"
            name="email" 
            placeholder="Email"
            ref={register({
              required: 'This field is required',
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Invalid email address',
              }
            })} 
          />
          {errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Country</Form.Label>
          <Form.Control 
            isInvalid={errors.country}
            as="select"
            name="country"
            ref={register({
              required: 'Please select a country from the list'
          })}>
            <option value=""></option>
            <option value="et">Ethiopia</option>
            <option value="gh">Ghana</option>
            <option value="ke">Kenya</option>
            <option value="ng">Nigeria</option>
            <option value="tz">Tanzania</option>
            <option value="ug">Uganda</option>
          </Form.Control>
          {errors.country && (
            <Form.Control.Feedback type="invalid">
              {errors.country.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            isInvalid={errors.password}
            type="password"
            name="password" 
            placeholder="Password"
            ref={register({
              required: 'This field is required',
            })} 
          />
          {errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm password</Form.Label>
          <Form.Control
            isInvalid={errors.passwordConfirmation}
            type="password"
            name="passwordConfirmation" 
            placeholder="Confirm password"
            ref={register({
              required: true,
              validate: value =>
                value === watch('password') ||
                "Password confirmation doesn't match password",
            })} 
          />
          {errors.passwordConfirmation && (
            <Form.Control.Feedback type="invalid">
              {errors.passwordConfirmation.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Button block type="submit">Submit</Button>
        </Form.Group>
      </fieldset>
    </Form>
  );
}

export default function() {
  const form = useForm();
  const [errorResponse, setErrorResponse] = useState();
  const { handleSubmit } = form;
  const { data, loading, error, sendRequest } = useApi('/auth/register', {
    delayed: true,
    method: 'POST',
    sendToken: false
  });

  const onSubmit = ({ name, email, password }) => {
    sendRequest({ name, email, password });
  };

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [error, setErrorResponse]);

  return (
    <div style={{ background: '#007bff' }}>
      <Container
        className="d-flex flex-column justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Row className="justify-content-center mb-5">
          <Col>
            <Card className="border-0 shadow-sm p-4">
              <Card.Body>
                <h2 className="mb-4 text-center">Create an account</h2>
                {errorResponse && (
                  <Error error={errorResponse} />
                )}
                {data?.user ? (
                  <>
                    Thank you for registering.
                  </>
                ) : (
                  <>
                    <p>
                      Please fill out and submit the form below to sign up for a user account.
                    </p>
                    <RegisterForm
                      {...form} 
                      disabled={loading}
                      onSubmit={handleSubmit(onSubmit)} 
                    />
                  </>
                )}
                <hr />
                <div className="text-center">
                  {'Or '}
                  <Link to="/login">log in</Link>
                  {' if you already have an account.'}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
