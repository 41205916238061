import React, { useContext, useState, useEffect } from 'react';
import { Breadcrumb, Row, Col, Table } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Error from 'components/Common/Error';
import Page from 'components/Dashboard/Page';
import PageRange from 'components/Common/PageRange';
import Pagination from 'components/Common/Pagination';
import Spinner from 'components/Common/Spinner';
import { SessionContext } from 'contexts/Session';
import { useApi } from 'hooks/Api';

function CampaignsTable({ campaigns }) {
  return campaigns?.length ? (
    <Table className="bg-white" size="sm" bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {campaigns.map(({ id, name, ...attrs }) => (
          <tr key={id}>
            <td>{id}</td>
            <td>
              <Link to={`/project/campaigns/${id}`}>
                {name}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <p>
      No campaigns available
    </p>
  );
}

export default function() {
  const PAGE_SIZE = 10;

  const { page } = useParams();
  const currentPage = page || 1;
  const offset = (currentPage - 1) * PAGE_SIZE;

  const { project } = useContext(SessionContext);
  const history = useHistory();
  const [errorResponse, setErrorResponse] = useState();
  const {
    data,
    error,
  } = useApi(`/projects/${project.id}/campaigns?offset=${offset}&limit=${PAGE_SIZE}`);
  const [campaigns, total] = data ? [data.campaigns, data.total] : [null, 0];

  const totalPages = Math.ceil(total / PAGE_SIZE);

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [data, error]);

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/project">
          <Breadcrumb.Item>
            Project
          </Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Campaigns</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        <h1 className="mb-4">
          Campaigns
        </h1>
        {errorResponse ? (
          <Error error={errorResponse} />
        ) : campaigns ? (
          <>
            {!!campaigns.length && (
              <PageRange 
                from={offset + 1}
                to={offset + campaigns.length}
                total={total}
              />
            )}
            <CampaignsTable campaigns={campaigns} />
            <Row>
              <Col sm={12} lg={8} className={totalPages > 1 ? "" : "d-none"}>
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onSelectPage={page => {
                    history.push(`/project/campaigns/page/${page}`);
                  }}
                />
              </Col>
              <Col sm={12} lg={4} className={totalPages > 1 ? "text-right" : ""}>
                <Link
                  to="/project/campaigns/new"
                  className="btn btn-sm btn-primary d-block d-lg-inline-block"
                >
                  Create new campaign
                </Link>
              </Col>
            </Row>
          </>
        ) : (
          <Spinner />
        )}
      </Page>
    </>
  );
}
