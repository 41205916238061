import React, { createContext, useState } from 'react';

export const SurveyContext = createContext({});

export const SurveyContextProvider = props => {
  // Initial values are obtained from the props
  const { children } = props;

  // Use State to keep the values
  const [survey, setSurvey] = useState({});
  const [states, setStates] = useState([]);
  const [transitions, setTransitions] = useState([]);

  const updateState = (state, index) => {
    let newStates = [...states];

    console.log(state);
    newStates[index] = { ...newStates[index], ...state };
    setStates(newStates);
  };

  const defaultState = {
    name: '',
    content: '',
    text: '',
    left: 0,
    top: 0,
    transition: 0
  };

  const addStates = statesToAdd => {
    const newStates = statesToAdd.map(state => ({
      ...state,
      feature_id: survey.id
    }));

    setStates([...states, ...newStates]);
  };

  const addState = (state = defaultState) => setStates([...states, state]);

  const moveState = (props, monitor) => {
    const item = monitor.getItem();
    const delta = monitor.getDifferenceFromInitialOffset();
    let newStates = [...states];

    const left = Math.round(newStates[item.index].left + delta.x);
    const top = Math.round(newStates[item.index].top + delta.y);

    newStates[item.index] = {
      ...newStates[item.index],
      top,
      left
    };

    setStates(newStates);
  };

  const removeState = stateToRemove => {
    let newStates = [...states];

    setStates(newStates.filter(state => state.id !== stateToRemove.id));
  };

  const surveyContext = {
    survey,
    setSurvey,
    states,
    setStates,
    updateState,
    addState,
    addStates,
    moveState,
    removeState,
    transitions,
    setTransitions
  };

  // pass the value in provider and return
  return (
    <SurveyContext.Provider value={surveyContext}>
      {children}
    </SurveyContext.Provider>
  );
};
