//import Campaigns from './Campaigns';
import Participants from './Participants';
//import List from './List';
import Create from './Create';
import Select from './Select';
import Current from './Current';
//import Countries from './Countries';

const components = {
//  Campaigns,
//  List,
  Create,
  Participants,
  Select,
//  Countries,
  Current,
};

export default components;
