import React, { useState, useEffect, useContext } from 'react';
import Error from '../Common/Error';
import Spinner from '../Common/Spinner';
import Page from '../Dashboard/Page';
import { UiContext } from '../../contexts/Ui';
import { Breadcrumb, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ProjectsTable } from './Select';
import { SessionContext } from '../../contexts/Session';
import { useApi } from '../../hooks/Api';

export default function() {
  const { data, error } = useApi('/projects');
  const [errorResponse, setErrorResponse] = useState();
  const { project, selectProject } = useContext(SessionContext);
  const { addToast } = useContext(UiContext);
  const projects = data ? data.projects : null;

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [data, error]);

  const changeProject = project => {
    selectProject(project);
    addToast(`Current working project was changed to '${project.name}'.`);
  };

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Project</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        <Card className="mb-4">
          <Card.Body>
            <Card.Text>
              Your current working project is: <b>{project.name}</b>.
            </Card.Text>
          </Card.Body>
        </Card>
        {errorResponse ? (
          <Error error={errorResponse} />
        ) : projects ? (
          <ProjectsTable 
            projects={projects} 
            onSelectProject={changeProject}
          />
        ) : (
          <Spinner />
        )}
      </Page>
    </>
  );
}
