import React, { useState, useEffect, useContext } from 'react';
import Error from '../Common/Error';
import Spinner from '../Common/Spinner';
import { Button, Container, Row, Col, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { SessionContext } from '../../contexts/Session';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { noDefault } from '../../helpers/Jsx';
import { useApi } from '../../hooks/Api';

export function ProjectsTable({ projects, onSelectProject }) {
  return projects?.length ? (
    <Table size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Last accessed</th>
          <th>Country</th>
        </tr>
      </thead>
      <tbody>
        {projects.map(({ id, name, ...attrs }) => (
          <tr key={id}>
            <td>{id}</td>
            <td>
              <a 
                href="/" 
                onClick={noDefault(() => onSelectProject({ id, name, ...attrs }))}
              >
                {name}
              </a>
            </td>
            <td>1</td>
            <td>1</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <>
      No projects
    </>
  );
}

export default function() {
  const { data, error } = useApi('/projects');
  const [errorResponse, setErrorResponse] = useState();
  const { selectProject } = useContext(SessionContext);
  const projects = data ? data.projects : null;

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [data, error]);

  return (
    <div style={{ background: '#1cc88a' }}>
      <Container
        className="d-flex flex-column justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Row className="justify-content-center mb-5">
          <Col>
            <Card className="border-0 shadow-sm p-4">
              <Card.Body>
                <h2 className="mb-4 text-center">
                  <span style={{ color: '#858796' }}>
                    <FontAwesomeIcon
                      className="mb-3"
                      icon={faComments}
                      style={{ 
                        fontSize: '64px', 
                        width: '100%',
                      }}
                    />
                  </span>
                  Choose your project
                </h2>
                {errorResponse ? (
                  <Error error={errorResponse} />
                ) : projects ? (
                  <>
                    <p className="color-gray">
                      Select the project you’d like to access from the list below.  
                    </p>
                    <ProjectsTable 
                      projects={projects} 
                      onSelectProject={selectProject}
                    />
                    <LinkContainer to="/projects/new">
                      <Button block size="sm">
                        Create a new project
                      </Button>
                    </LinkContainer>
                  </>
                ) : (
                  <Spinner />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
