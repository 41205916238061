import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import api from '../../../../_api';

export default function CreateSurvey() {
  const { id } = useParams();
  const [participants, setParticipants] = useState([]);
  const [name, setName] = useState('');
  const [message] = useState('');
  const [participantIds, setParticipantIds] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    const fetchParticipants = async () => {
      const response = await api('/participants', { method: 'GET' });

      setParticipants(response.data.participants);
    };

    fetchParticipants();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    if (name && participantIds.length > 0) {
      let ids = [];

      for (let p of participantIds) {
        ids.push(p.value);
      }

      const response = await api('/surveys', {
        method: 'POST',
        data: {
          name,
          message,
          channel: 'twilio_voice',
          campaign_id: id,
          flow_id: 1, // Hard coded for now
          participant_ids: ids.join(','),
        },
      });

      if (response.data.announcement) {
        setIsCompleted(true);
      }
    }
  };

  if (isCompleted) {
    return <Redirect to={`/campaigns/${id}`} />;
  }

  if (!participants) {
    return (
      <progress className="progress is-large is-info" max="100">
        15%
      </progress>
    );
  }

  return (
    <div>
      <h1>Survey</h1>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Text input"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Channel</label>
          <div className="control">
            <div className="select">
              <select>
                <option>Voice</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">Participants</label>
          <div className="control">
            <div
              className="select is-multiple"
              value={participantIds}
              onChange={e => setParticipantIds(e.target.selectedOptions)}
            >
              <select multiple>
                {participants.map(participant => (
                  <option value={participant.id} key={participant.id}>
                    {participant.name} - {participant.phone_number}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <button type="submit" className="button is-primary">
            Create
          </button>
        </div>
      </form>
    </div>
  );
}
