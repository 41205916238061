import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Page from '../../Dashboard/Page';

export default function() {
  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/project">
          <Breadcrumb.Item>
            Project
          </Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/project/participants">
          <Breadcrumb.Item>
            Participants
          </Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>…</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        show
      </Page>
    </>
  )
}
