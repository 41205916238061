import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SurveyContext } from '../_contexts/Survey';
import api from '../_api';

export default function StateModal({ state, index, show, setShow }) {
  const { handleSubmit, register } = useForm();
  const handleClose = () => setShow(false);

  const { updateState, removeState } = useContext(SurveyContext);

  const onSubmit = async values => {
    const formData = new FormData();

    formData.append('state[name]', values.name);
    formData.append('state[text]', values.text);
    formData.append('state[transition]', values.transition);

    if (values.audio.length > 0) {
      formData.append('state[audio]', values.audio[0]);
    }

    const response = await api(`/states/${state.id}`, {
      data: formData,
      method: 'put',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response?.data?.state) {
      updateState(response.data.state, index);
      handleClose();
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want delete this state?')) {
      const response = await api(`/states/${state.id}`, { method: 'DELETE' });

      if (response?.data?.success) {
        removeState(state);
        handleClose();
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>State</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              defaultValue={state.name}
              ref={register({
                required: 'Required'
              })}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Text</Form.Label>
            <Form.Control
              defaultValue={state.text}
              ref={register({
                required: 'Required'
              })}
              name="text"
              as="textarea"
              rows="3"
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.Audio">
            <Form.Label>Audio file</Form.Label>
            <input type="file" ref={register({})} name="audio" />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Transition</Form.Label>
            <Form.Control
              defaultValue={state.transition}
              ref={register({
                required: 'Required'
              })}
              name="transition"
              as="select"
            >
              <option value="0">None</option>
              <option value="1">Single</option>
              <option value="2">Binary</option>
              <option value="3">Multiple choice</option>
              <option value="4">Open ended</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
