import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import Page from 'components/Dashboard/Page';

export default function() {
  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Content</Breadcrumb.Item>
        <LinkContainer to="/content/messages">
          <Breadcrumb.Item>Text messages</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>New</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        <h1 className="mb-4">
          Create text message
        </h1>
      </Page>
    </>
  );
}
