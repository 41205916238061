import React, { useEffect, useContext, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import Error from 'components/Common/Error';
import { SessionContext } from 'contexts/Session';
import { useApi } from 'hooks/Api'

export function LoginForm({ register, errors, disabled, onSubmit }) {
  return (
    <Form onSubmit={onSubmit}>
      <fieldset disabled={disabled}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            isInvalid={errors.email}
            type="text"
            name="email" 
            ref={register({
              required: 'This field is required',
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Invalid email address',
              }
            })} 
          />
          {errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            isInvalid={errors.password}
            type="password" 
            name="password" 
            ref={register({
              required: 'This field is required'
            })} 
          />
          {errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Button block type="submit">Log in</Button>
        </Form.Group>
      </fieldset>
    </Form>
  );
}

export default function() {
  const form = useForm();
  const { login } = useContext(SessionContext);
  const [errorResponse, setErrorResponse] = useState();
  const { handleSubmit, reset } = form;
  const { data, loading, error, sendRequest } = useApi('/auth/login', {
    delayed: true,
    method: 'POST',
    sendToken: false
  });

  const onSubmit = ({ email, password }) => {
    sendRequest({ email, password });
  };

  useEffect(() => {
    if (error) {
      if (error.response) {
        if (401 === error.response.status) {
          reset();
          setErrorResponse('Authentication failed');
        } else {
          setErrorResponse(error.response.statusText);
        }
      } else {
        setErrorResponse('Network error');
      }
    } else if (data) {
      if (data.token) {
        login(data);
      } else {
        setErrorResponse('Application error');
      }
    }
  }, [error, data, login, reset, setErrorResponse]);

  return (
    <div style={{ background: '#007bff' }}>
      <Container
        className="d-flex flex-column justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Row className="justify-content-center mb-5">
          <Col>
            <Card className="border-0 shadow-sm p-4">
              <Card.Body>
                <h2 className="mb-4 text-center">Log in</h2>
                {errorResponse && (
                  <Error error={errorResponse} />
                )}
                <LoginForm
                  {...form} 
                  disabled={loading}
                  onSubmit={handleSubmit(onSubmit)} 
                />
                <hr />
                <div className="text-center">
                  <span>Forgot password?</span>
                  <span className="mx-2">|</span>
                  <Link to="/register">Create an account</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
