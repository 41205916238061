import Item from './Item';
import ItemGroup from './ItemGroup';
import Submenu from './Submenu';
import SubmenuDivider from './SubmenuDivider';
import SubmenuItem from './SubmenuItem';
import SubmenuTitle from './SubmenuTitle';
import ToggleButton from './ToggleButton';

const Menu = {
  Item,
  ItemGroup,
  Submenu,
  SubmenuTitle,
  SubmenuItem,
  SubmenuDivider,
  ToggleButton,
};

export default Menu;
