import React from 'react';
import {
  PieChart, Pie, Sector, ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area 
} from 'recharts';

export default function({ data, country }) {

  let prev = 0;

  const proc = Object.keys(data.timeline.cases).map(date => {
    const cases = data.timeline.cases[date];
    const newCases = Math.max(0, cases - prev);
    prev = cases;
    return { date, newCases };
  }).slice(1);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={proc}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar name="New cases" dataKey="newCases" stackId="a" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
}

