import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  height: 100px;
`;

export default function () {
  return (
    <Container>
      <Spinner
        animation="border"
        role="status"
        style={{ color: 'rgb(133, 135, 150)' }}
      >
        <span className="sr-only">Loading&hellip;</span>
      </Spinner>
    </Container>
  );
}
