import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UiContext } from '../../../../../contexts/Ui';
import styled from 'styled-components';

const Link = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: ${({ isRibbon }) => (isRibbon ? 'column' : 'row')};
  justify-content: ${({ isRibbon }) => (isRibbon ? 'center' : 'space-between')};
  align-items: center;
  width: 100%;
  min-height: ${({ isRibbon }) => (isRibbon ? '4.8em' : '2.7em')};
  color: rgba(255, 255, 255, 0.3);
  line-height: 1.2;
  font-size: ${({ isRibbon }) => (isRibbon ? '0.85em' : '0.95em')};

  a,
  span.title {
    color: rgba(255, 255, 255, 0.75);
    padding: ${({ isRibbon }) => (isRibbon ? '0' : '0.6em 0')};
  }

  :hover,
  :hover a,
  :hover span.title {
    color: #fff;
  }
`;

const Container = styled.div`
  margin-top: ${({ isRibbon }) => (isRibbon ? '0.5em' : '0')};
  text-align: ${({ isRibbon }) => (isRibbon ? 'center' : 'left')};
  flex: ${({ isRibbon }) => (isRibbon ? 'inherit' : '1')};
  align-items: center;
  display: flex;

  a,
  span.title {
    flex: 1;
  }
`;

export default function ({ icon, children, title, ...props }) {
  const { isMobile, sidebarExpanded } = useContext(UiContext);
  return (
    <Link isRibbon={isMobile || !sidebarExpanded} {...props}>
      {icon && (
        <span style={{ minWidth: '27px' }}>
          <FontAwesomeIcon
            fixedWidth
            icon={icon}
            style={{ fontSize: isMobile || !sidebarExpanded ? '1.6em' : '1em' }}
          />
        </span>
      )}
      <Container isRibbon={isMobile || !sidebarExpanded}>
        {title && <span className="title">{title}</span>}
        {children}
      </Container>
    </Link>
  );
}
