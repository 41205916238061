import React from 'react';
import {
  Line, ComposedChart, PieChart, Pie, Sector, ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area 
} from 'recharts';

export default function({ data }) {

  let total = {
    timeline: {
      cases: {},
      deaths: {},
      recovered: {}
    }
  };

  data.forEach(country => {
    if (!country.timeline)
      return;

    Object.keys(country.timeline.cases).forEach(key => {
      if (total.timeline.cases.hasOwnProperty(key)) {
        total.timeline.cases[key] += country.timeline.cases[key];
      } else {
        total.timeline.cases[key] = country.timeline.cases[key];
      }
    });

    Object.keys(country.timeline.deaths).forEach(key => {
      if (total.timeline.deaths.hasOwnProperty(key)) {
        total.timeline.deaths[key] += country.timeline.deaths[key];
      } else {
        total.timeline.deaths[key] = country.timeline.deaths[key];
      }
    });

    Object.keys(country.timeline.recovered).forEach(key => {
      if (total.timeline.recovered.hasOwnProperty(key)) {
        total.timeline.recovered[key] += country.timeline.recovered[key];
      } else {
        total.timeline.recovered[key] = country.timeline.recovered[key];
      }
    });
  });

  const proc = Object.keys(total.timeline.cases).map(date => {
    const cases = total.timeline.cases[date];
    const deaths = total.timeline.deaths[date];
    const recovered = total.timeline.recovered[date];
    const active = cases - (deaths + recovered);
    return { date, cases, deaths, recovered, active };
  });

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        data={proc}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Area name="Active" dataKey="active" stackId="a" stroke="#82ca9d" fill="#82ca9d" />
        <Area name="Recovered" dataKey="recovered" stackId="a" stroke="#8884d8" fill="#8884d8" />
        <Area name="Deaths" dataKey="deaths" stackId="a" stroke="#d88488" fill="#d88488" />
        <Line name="Confirmed" dataKey="cases" stackId="b" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
