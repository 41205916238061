import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

export default function({ from, to, total }) {
  return (
    <Row className="align-items-center mb-2">
      <Col>
        <div className="color-gray">
          Showing entries {from}&ndash;{to} of {total}
        </div>
      </Col>
      <Col className="d-flex align-items-center justify-content-end">
        <div className="color-gray">Show</div>
        <Form.Control
          size="sm"
          as="select"
          style={{ margin: '0 0.5em', width: '100px' }}
        >
          <option>10</option>
          <option>20</option>
          <option>50</option>
          <option>100</option>
        </Form.Control>
        <div className="color-gray">entries</div>
      </Col>
    </Row>
  );
}
