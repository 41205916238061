import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { UiContext } from 'contexts/Ui';

const Toast = styled.div`
  padding: 2em 1.4em; 
  margin: 0.5em 0 0; 
  width: ${({ isMobile }) => isMobile ? '100%' : '500px'}}; 
  background: rgba(39, 183, 183); 
  color: white;
  border-radius: .3rem;
  z-index: 10;
  opacity: ${({ expired }) => expired ? '0' : '1'};
  transition: opacity 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  position: fixed;
  width: calc(100% - ${({ padding }) => padding}rem);
  margin-left: ${({ padding }) => padding}rem;
  padding: 1em;
`;

export default function() {
  const { 
    isMobile, 
    sidebarExpanded, 
    toasts, 
    addToast, 
    dismissToast 
  } = useContext(UiContext);

  const padding =
      !isMobile && sidebarExpanded
          ? '14'
          : isMobile && !sidebarExpanded 
          ? '0'
          : '6.5';

  return (
    <Container padding={padding}>
      {toasts.map(({ toastId, message, expired }) => (
        <Toast 
          className="animated--grow-in noselect" 
          key={toastId}
          isMobile={isMobile}
          expired={expired}
        >
          {message}
          <FontAwesomeIcon 
            onClick={() => dismissToast(toastId)}
            style={{ cursor: 'pointer' }} 
            icon={faTimes} 
          />
        </Toast>
      ))}
    </Container>
  );
}
