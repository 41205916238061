import React, { createContext, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';

export const UiContext = createContext({});

function useMenu() {
  const [expanded, setExpanded] = useState(null);

  const closeAll = () => {
    setExpanded(null);
  };

  const toggleExpanded = key => {
    setExpanded(isExpanded(key) ? null : key);
  };

  const isExpanded = key => {
    return expanded === key;
  };

  return {
    setExpanded,
    toggleExpanded,
    isExpanded,
    closeAll,
  };
}

function useToasts() {
  const [toasts, setToasts] = useState([]);
  const [toastId, setToastId] = useState(1);
  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;

  const expireToast = id => {
    setToasts(toastsRef.current.map(toast => 
      toast.toastId === id ? { ...toast, expired: true } : toast
    ));
  };

  const removeToast = id => {
    setToasts(toastsRef.current.filter(({ toastId }) => toastId !== id));
  };

  const dismissToast = id => {
    setToasts(toastsRef.current.map(toast => 
      toast.toastId === id ? { ...toast, expired: true } : toast
    ));
    setTimeout(() => { removeToast(id); }, 400)
  };

  const addToast = info => {
    const message = 'object' === typeof(info) ? (
      <>
        {info.icon && (
          <FontAwesomeIcon
            icon={info.icon}
            style={{ fontSize: '1.1em', marginRight: '0.7em', marginBottom: '2px' }}
          />
        )}
        <div style={{ flex: 1 }}>{info.message}</div>
      </>
    ) : info;
    setToasts(Array.prototype.concat({ 
      toastId, 
      message, 
      expired: false 
    }, toasts));
    setTimeout(() => { expireToast(toastId); }, 3000)
    setTimeout(() => { removeToast(toastId); }, 3400)
    setToastId(toastId + 1);
  };

  return { toasts, addToast, dismissToast };
}

export function UiContextProvider({ children }) {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const topMenu = useMenu();
  const sidebarMenu = useMenu();
  const { toasts, addToast, dismissToast } = useToasts();

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  const uiContext = {
    isMobile,
    sidebarExpanded,
    toggleSidebar,
    topMenu,
    sidebarMenu,
    addToast,
    dismissToast,
    toasts
  };

  return (
    <UiContext.Provider value={uiContext}>
      {children}
    </UiContext.Provider>
  );
}
