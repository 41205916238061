import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

export default function({ data, country }) {

  const filtered = 
      data.filter(entry => entry?.countryInfo?.iso2 === country);

  const countryData = filtered ? filtered[0] : null;

  const date = new Date(countryData.updated);

  return (
    <>
      {countryData ? (
        <>
          <Card.Header as="h5">
            {countryData.country}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6} lg={3}>
                <div style={{ minHeight: '220px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '160px', height: '160px', background: 'rgb(136, 132, 216)', color: 'white' }}>
                    <h4>Confirmed</h4>
                    <h3>{countryData.cases}</h3>
                  </div>
                </div>
              </Col>
              <Col sm={6} lg={3}>
                <div style={{ minHeight: '220px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '160px', height: '160px', background: '#17a2b8', color: 'white' }}>
                  <h4>Active</h4>
                  <h3>{countryData.active}</h3>
                </div>
                </div>
              </Col>
              <Col sm={6} lg={3}>
                <div style={{ minHeight: '220px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '160px', height: '160px', background: 'rgb(130, 202, 157)', color: 'white' }}>
                  <h4>Recovered</h4>
                  <h3>{countryData.recovered}</h3>
                </div>
                </div>
              </Col>
              <Col sm={6} lg={3}>
                <div style={{ minHeight: '220px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '160px', height: '160px', background: 'rgb(216, 132, 136)', color: 'white' }}>
                  <h4>Deaths</h4>
                  <h3>{countryData.deaths}</h3>
                </div>
                </div>
              </Col>
            </Row>
            {/*
            <Row>
              <Col>
                Cases today: {countryData.todayCases}
              </Col>
            </Row>
            */}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Last updated {date.toDateString()}</small>
          </Card.Footer>
        </>
      ) : (
        <Card.Body>
          No data available
        </Card.Body>
      )}
    </>
  );
}
