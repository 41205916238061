import React, { useContext } from 'react';
import { SurveyContext } from './../_contexts/Survey';

export default function Transition({ transition }) {
  const { states } = useContext(SurveyContext);
  let x1 = -1;
  let y1 = -1;
  let x2 = -1;
  let y2 = -1;

  if (states.length > 0) {
    for (let state of states) {
      if (state.id === transition.previous_state_id) {
        x1 = state.left + 100;
        y1 = state.top + 200;
      } else if (state.id === transition.next_state_id) {
        x2 = state.left + 100;
        y2 = state.top + 100;
      }
    }
  }

  // If previous or next id couldn't be found, we hide the transition
  if (x1 === -1 || y1 === -1 || x2 === -1 || y2 === -1) {
    return null;
  }

  return <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="black" />;
}
