import React, { useState, useEffect } from 'react';
import { Card, Button, ButtonGroup } from 'react-bootstrap';
import {
  PieChart, Pie, Sector, ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, Text
} from 'recharts';

export default function({ data }) {
  const [mode, setMode] = useState('cases');
  const [info, setInfo] = useState();

  useEffect(() => {
    if (data) {

      switch (mode) {
        case 'deaths_per_million':
          setInfo({
            dataKey: 'deathsPerOneMillion',
            filtered: data.sort((a, b) => b.deathsPerOneMillion - a.deathsPerOneMillion).slice(0, 8),
            renderCustomizedLabel: ({ name, deathsPerOneMillion }) => `${name}: ${deathsPerOneMillion}`,
            criteria: 'deaths per one million people',
          });
          break;

        case 'cases_per_million':
          setInfo({
            dataKey: 'casesPerOneMillion',
            filtered: data.sort((a, b) => b.casesPerOneMillion - a.casesPerOneMillion).slice(0, 8),
            renderCustomizedLabel: ({ name, casesPerOneMillion }) => `${name}: ${casesPerOneMillion}`,
            criteria: 'cases per one million people',
          });
          break;

        case 'deaths':
          setInfo({
            dataKey: 'deaths',
            filtered: data.sort((a, b) => b.deaths - a.deaths).slice(0, 8),
            renderCustomizedLabel: ({ name, deaths }) => `${name}: ${deaths}`,
            criteria: 'deaths',
          });
          break;

        case 'cases':
        default: 
          setInfo({
            dataKey: 'cases',
            filtered: data.sort((a, b) => b.cases - a.cases).slice(0, 8),
            renderCustomizedLabel: ({ name, cases }) => `${name}: ${cases}`,
            criteria: 'cases',
          });
      }

    }
  }, [data, mode]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <>
      {info && (
        <Card.Body>
          <div>
            <ButtonGroup className="mb-4" size="sm" style={{ width: '100%' }}>
              <Button style={'cases' === mode ? { background: '#444' } : {}} onClick={() => { setMode('cases'); }} variant="secondary">Number of cases</Button>
              <Button style={'deaths' === mode ? { background: '#444' } : {}} onClick={() => { setMode('deaths'); }} variant="secondary">Number of deaths</Button>
              <Button style={'cases_per_million' === mode ? { background: '#444' } : {}} onClick={() => { setMode('cases_per_million'); }} variant="secondary">Cases per one million</Button>
              <Button style={'deaths_per_million' === mode ? { background: '#444' } : {}} onClick={() => { setMode('deaths_per_million'); }} variant="secondary">Deaths per one million</Button>
            </ButtonGroup>
          </div>
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Pie nameKey="country" dataKey={info.dataKey} data={info.filtered} label={info.renderCustomizedLabel}>
                {data.map((entry, index) => 
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                )}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <hr />
          <p style={{ marginBottom: 0 }}>
            Breakdown of the 8 most affected countries in Africa, based on {info.criteria}.
          </p>
        </Card.Body>
      )}
    </>
  );
}
