import React, { useContext } from 'react';
import styled from 'styled-components';
import { UiContext } from 'contexts/Ui';

const Item = styled.span`
  cursor: pointer;
  display: block;

  :hover {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }

  a {
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.9em;
    padding: 0.5em 1em;
    display: block;
  }
`;

export default function ({ children, ...props }) {
  const { sidebarMenu, topMenu } = useContext(UiContext);
  const closeAll = () => {
    sidebarMenu.closeAll();
    topMenu.closeAll();
  };
  return (
    <li {...props}>
      <Item onClick={closeAll}>{children}</Item>
    </li>
  );
}
