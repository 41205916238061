import React from 'react';
import { Alert } from 'react-bootstrap';

export default function ({ error }) {
  return (
    <Alert variant="danger">
      <Alert.Heading>Error</Alert.Heading>
      <p className="mb-0">Server responded with message: <b>{error}</b></p>
    </Alert>
  );
}
