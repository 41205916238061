import React, { useEffect, useContext, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import Error from 'components/Common/Error';
import Page from 'components/Dashboard/Page';
import { SessionContext } from 'contexts/Session';
import { useApi } from 'hooks/Api'

function ProjectForm({ register, errors, disabled, onSubmit }) {
  return (
    <Form onSubmit={onSubmit}>
      <fieldset disabled={disabled}>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            isInvalid={errors.name}
            type="text"
            name="name" 
            ref={register({
              required: 'This field is required'
            })} 
          />
          {errors.name && (
            <Form.Control.Feedback type="invalid">
              {errors.name.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Button type="submit">Save project</Button>
        </Form.Group>
      </fieldset>
    </Form>
  );
}

export default function() {
  const form = useForm();
  const [errorResponse, setErrorResponse] = useState();
  const { project } = useContext(SessionContext);
  const { handleSubmit } = form;
  const { data, loading, error, sendRequest } = useApi('/projects', {
    delayed: true,
    method: 'POST'
  });

  const onSubmit = ({ name }) => {
    sendRequest({ name, project_id: project.id });
  };

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [error, data, setErrorResponse]);

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>New project</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        <h2 className="mb-4">
          Create new project
        </h2>
        {data?.project && (
          <Redirect to="/project" />
        )}
        {errorResponse && (
          <Error error={errorResponse} />
        )}
        <ProjectForm
          {...form} 
          disabled={loading}
          onSubmit={handleSubmit(onSubmit)} 
        />
      </Page>
    </>
  );
}
