import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { SessionContext } from './contexts/Session';
import { API_URL } from './config';
import { useHistory } from 'react-router-dom';

export function useApi(url, page) {
  const [count, setCount] = useState(0);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const { logout } = useContext(SessionContext);
  const history = useHistory();

  const reload = () => {
    setCount(count + 1);
  };

  const offset = page ? page.offset : null;
  const limit = page ? page.limit : null;

  useEffect(() => {
    let isMounted = true;

    (async () => {
      const query =
        offset !== null && limit !== null ? { offset, limit } : null;

      try {
        const response = await api(url, { method: 'GET', query });
        if (isMounted) {
          setData(response.data);
        }
      } catch (error) {
        if (isMounted) {
          if (error.response) {
            if (401 === error.response.status) {
              logout();
              history.push('/login');
            } else {
              setError(`Error: ${error.response.statusText}`);
            }
          } else {
            setError('Service unavailable. Are you connected to the Internet?');
          }
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [offset, limit, setData, url, history, logout]);

  return { data, error, reload };
}

const defaultHeaders = {
  'Content-Type': 'application/json',
};

function api(uri, { method = 'GET', headers = defaultHeaders, data, query }) {
  const urlString = uri.match(/^http/) ? uri : `${API_URL}${uri}`;
  const { token } = JSON.parse(localStorage.getItem('session'));
  const url = new URL(urlString);

  let options = {
    mode: 'cors',
    headers: headers,
    method,
    query,
  };

  if (['POST', 'PUT'].indexOf(method.toUpperCase()) !== -1 && data) {
    options.data = data;
  }

  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`;
  }

  if (options.query) {
    url.search = new URLSearchParams(options.query).toString();
  }

  options.url = url.toString();

  console.log(options);
  return axios(options);
}

export default api;
