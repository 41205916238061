import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Form, Breadcrumb } from 'react-bootstrap';
import Page from 'components/Dashboard/Page';
import { useForm } from 'react-hook-form'
import { useApi } from 'hooks/Api'

function UploadForm({ watch, register, errors, disabled, onSubmit }) {
  const [fileList, setFileList] = useState(null);
  const fileField = watch('audio');

  useEffect(() => {
    if (fileField) {
      setFileList(Array.from(fileField).map(file => file.name));
    } else {
      setFileList(null);
    }
  }, [fileField]);
  
  return (
    <Form onSubmit={onSubmit}>
      <fieldset disabled={disabled}>
        <Form.Group>
          <Form.Label>Phone number</Form.Label>
          <Form.Control
            isInvalid={errors.number}
            type="text"
            name="number" 
            ref={register({
              required: 'This field is required'
            })} 
          />
          {errors.number && (
            <Form.Control.Feedback type="invalid">
              {errors.number.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Audio file</Form.Label>
          {!!fileList?.length && (
            <Form.Control.Feedback className="mb-2" type="valid" style={{ display: 'block' }}>
              {fileList.map((name, i) => (
                <span key={i}>
                  {name}
                </span>
              ))}
            </Form.Control.Feedback>
          )}
          <Form.File 
            isInvalid={errors.audio}
            label={fileList ? '' : 'Please select a file'}
            custom
            name="audio" 
            ref={register({
              required: 'This field is required'
            })} 
          />
          {errors.audio && (
            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
              {errors.audio.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Button type="submit">Save</Button>
        </Form.Group>
      </fieldset>
    </Form>
  );
}

export default function() {
  const form = useForm();
  const [errorResponse, setErrorResponse] = useState();
  //const { addToast } = useContext(UiContext);
  const { handleSubmit } = form;
  const { data, loading, error, sendRequest } = useApi('/results', {
    delayed: true,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  const onSubmit = (values) => {
    console.log(values);
    const formData = new FormData();

    formData.append('phone_number', values.phoneNumber);
    formData.append('audio', values.audio[0]);

    sendRequest(formData);
  };

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/tasks/questions">
          <Breadcrumb.Item>
            Questions
          </Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Upload</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        <h1 className="mb-4">
          Upload question
        </h1>
        <UploadForm 
          {...form} 
          disabled={loading}
          onSubmit={handleSubmit(onSubmit)} 
        />
      </Page>
    </>
  );
}
