function getSession() {
  try {
    return JSON.parse(localStorage.getItem('session'));
  } catch (err) {
    return null;
  }
}

function setSession(session) {
  localStorage.setItem('session', JSON.stringify(session));
}

const api = {
  getSession,
  setSession
};

export default api;
