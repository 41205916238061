import React, { useContext } from 'react';
import Menu from '../Dashboard/Sidebar/Menu';
import Avatar from '../../assets/avatar.jpg';
import { noDefault } from '../../helpers/Jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup, ActionItem } from '../Dashboard/Header';
import { Link } from 'react-router-dom';
import { UiContext } from '../../contexts/Ui';
import { Navbar, Badge } from 'react-bootstrap';
import { SessionContext } from '../../contexts/Session';
import {
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

export default function () {
  const {
    topMenu: { isExpanded, toggleExpanded },
    isMobile,
  } = useContext(UiContext);
  const { user, logout } = useContext(SessionContext);

  return (
    <Navbar.Collapse>
      
      <a 
        href="/" 
        onClick={noDefault(logout)}
        style={{ 
          color: 'rgb(255, 255, 255, 0.8)', 
          marginRight: '0.5em', 
          padding: '0.4em 1em',
          background: 'rgba(255, 255, 255, 0.15)',
          borderRadius: '4px'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FontAwesomeIcon
            icon={faSignOutAlt}
            style={{ display: 'block', marginRight: '0.4em', fontSize: '1.3em' }}
          />
          <div>
            Log out
          </div>
        </div>
      </a>

      {/*
      <div style={{ margin: '0 0.5em', height: '50px', minWidth: '40px' }}>
        <Popup
          open={isExpanded('notifications')}
          style={{ width: '330px', marginLeft: '-70px', padding: 0 }}
        >
          <div
            style={{
              margin: '0',
              background: '#27b7b7',
              padding: '0.5em 1em',
            }}
          >
            <span
              style={{
                margin: '0',
                color: 'rgba(255, 255, 255, 0.8)',
                textTransform: 'uppercase',
                fontSize: '0.7em',
                fontWeight: '800',
              }}
            >
              Notifications
            </span>
          </div>
          <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
            <Menu.SubmenuItem>
              <Link
                to="/"
                style={{
                  margin: '0.5em',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <FontAwesomeIcon
                    style={{ fontSize: '3em', marginRight: '0.4em' }}
                    icon={faSmile}
                  />
                </div>
                <div>
                  Welcome to Farm Radio Interactive. This is a prototype, so
                  don’t get too excited!
                </div>
              </Link>
            </Menu.SubmenuItem>
            <Menu.SubmenuDivider style={{ margin: 0 }} />
            <Menu.SubmenuItem>
              <Link to="/notifications">All notifications</Link>
            </Menu.SubmenuItem>
          </ul>
        </Popup>

        <ActionItem
          onClick={() => {
            toggleExpanded('notifications');
          }}
        >
          <Badge
            variant="danger"
            style={{
              position: 'absolute',
              marginLeft: '12px',
              marginTop: '-10px',
              fontSize: '0.9em',
            }}
          >
            2
          </Badge>

          <FontAwesomeIcon
            className="hl"
            icon={faBell}
            style={{
              fontSize: '1.5em',
              color: 'rgba(255, 255, 255, 0.8)',
            }}
          />
        </ActionItem>
      </div>

      <div style={{ margin: '0 0.5em', height: '50px' }}>
        <Popup open={isExpanded('language-selector')}>
          <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
            <Menu.SubmenuTitle>Language</Menu.SubmenuTitle>
            <Menu.SubmenuItem>
              <Link to="/language/en">English</Link>
            </Menu.SubmenuItem>
            <Menu.SubmenuItem>
              <Link to="/language/fr">Français</Link>
            </Menu.SubmenuItem>
            <Menu.SubmenuItem>
              <Link to="/language/ki">Kiswahili</Link>
            </Menu.SubmenuItem>
            <Menu.SubmenuItem>
              <Link to="/language/ep">Esperanto</Link>
            </Menu.SubmenuItem>
          </ul>
        </Popup>

        <ActionItem
          onClick={() => {
            toggleExpanded('language-selector');
          }}
        >
          <div
            className="hl"
            style={{
              marginRight: '9px',
              color: 'rgb(255, 255, 255, 0.8)',
              fontWeight: '700',
            }}
          >
            en-us
          </div>
          <div style={{ minWidth: '16px' }}>
            <FontAwesomeIcon
              className="hl"
              icon={isExpanded('language-selector') ? faAngleDown : faAngleRight}
              style={{ color: 'rgb(255, 255, 255, 0.8)' }}
            />
          </div>
        </ActionItem>
      </div>

      <div style={{ margin: '0 0.5em', height: '50px' }}>
        <Popup
          open={isExpanded('account')}
          style={{ marginLeft: isMobile ? '-70px' : '-30px' }}
        >
          <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
            <Menu.SubmenuTitle>Account</Menu.SubmenuTitle>
            <Menu.SubmenuItem>
              <Link to="/user/profile">Profile</Link>
            </Menu.SubmenuItem>
            <Menu.SubmenuDivider />
            <Menu.SubmenuTitle>Project</Menu.SubmenuTitle>
            <Menu.SubmenuItem>
              <Link to="/project/settings">Settings</Link>
            </Menu.SubmenuItem>
            <Menu.SubmenuItem>
              <Link to="/project">Change project</Link>
            </Menu.SubmenuItem>
            <Menu.SubmenuItem>
              <Link to="/projects/new">New project</Link>
            </Menu.SubmenuItem>
            <Menu.SubmenuDivider />
            <Menu.SubmenuItem>
              <a href="/" onClick={noDefault(() => logout())}>
                Log out
              </a>
            </Menu.SubmenuItem>
          </ul>
        </Popup>

        <ActionItem
          onClick={() => {
            toggleExpanded('account');
          }}
        >
          <div
            style={{
              marginRight: '10px',
              alignSelf: 'center',
              borderRadius: '50%',
              width: '2.7em',
              height: '2.7em',
              background: `url(${Avatar})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          />
          {!isMobile && (
            <div
              className="hl"
              style={{
                color: 'rgb(255, 255, 255, 0.8)',
                marginRight: '10px',
              }}
            >
            {user.name}
            </div>
          )}
          <div style={{ minWidth: '20px', paddingTop: '1px' }}>
            <FontAwesomeIcon
              className="hl"
              icon={isExpanded('account') ? faAngleDown : faAngleRight}
              style={{ color: 'rgb(255, 255, 255, 0.8)' }}
            />
          </div>
        </ActionItem>
      </div>
    */}
    </Navbar.Collapse>
  );
}
