import React, { useRef, useContext, useEffect } from 'react';
import Img from 'assets/home_bg3.jpg';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { UiContext } from 'contexts/Ui';

const Container = styled.div`
  height: 4.375rem;
  width: 100%;
`;

const Nav = styled(Navbar)`
  background-color: #b72727;
  height: 100%;
`;

export const Hamburger = styled.span`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Brand = styled(Navbar.Brand)`
  font-size: 1.25rem;
  margin-top: -1px;
  font-weight: 400;

  a:focus {
    outline: none;
  }
`;

export const ActionItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover .hl {
    color: rgba(255, 255, 255, 1) !important;
  }
`;

const Collapsible = styled.div`
  background: #ffffff;
  overflow: hidden;
  display: ${({ open }) => (open ? 'block' : 'none')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  padding: 0.8em 0.5em;
  border-radius: 4px;
  min-width: 9.5rem;
  position: absolute;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.25);
  z-index: 20;
  margin-left: -40px;
  margin-top: 66px;
`;

export function Popup({ open, children, ...props }) {
  return (
    <Collapsible
      className={open ? 'animated--grow-in' : ''}
      open={open}
      {...props}
    >
      {children}
    </Collapsible>
  );
}

export default function({ children, ...props }) {
  const node = useRef();
  const {
    topMenu: { closeAll },
    toggleSidebar,
    isMobile,
  } = useContext(UiContext);

  useEffect(() => {
    const handleClick = e => {
      if (node.current.contains(e.target)) {
        return;
      }
      closeAll();
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [closeAll]);

  return (
    <Container className="noselect">
      <Nav {...props} style={{ justifyContent: 'space-between' }}>
        <div>
          {isMobile && (
            <Hamburger onClick={toggleSidebar}>
              <FontAwesomeIcon
                icon={faBars}
                style={{ 
                  color: 'rgba(255, 255, 255, 0.9)', 
                  fontSize: '1.1em' 
                }}
              />
            </Hamburger>
          )}
          <span style={{ marginLeft: isMobile ? '3em' : '0' }}>
            <Brand>
              <Link to="/" style={{ color: 'rgb(255, 255, 255, 0.9)' }}>
                Covid-19 Broadcaster Voice Q&A 
              </Link>
            </Brand>
          </span>
        </div>
        <div ref={node}>{children}</div>
      </Nav>
    </Container>
  );
}
