import React from 'react';
import {
  PieChart, Pie, Sector, ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area 
} from 'recharts';

export default function({ data }) {

  let total = {
    timeline: {
      cases: {},
      deaths: {},
      recovered: {}
    }
  };

  data.forEach(country => {
    if (!country.timeline)
      return;

    Object.keys(country.timeline.cases).forEach(key => {
      if (total.timeline.cases.hasOwnProperty(key)) {
        total.timeline.cases[key] += country.timeline.cases[key];
      } else {
        total.timeline.cases[key] = country.timeline.cases[key];
      }
    });
  });

  let prev = 0;

  const proc = Object.keys(total.timeline.cases).map(date => {
    const cases = total.timeline.cases[date];
    const newCases = cases - prev;
    prev = cases;
    //const deaths = total.timeline.deaths[date];
    //const recovered = total.timeline.recovered[date];
    //const active = cases - (deaths + recovered);
    return { date, newCases };
  }).slice(1);

 // console.log('----');
 // console.log(proc);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={proc}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar name="New cases" dataKey="newCases" stackId="a" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
}

