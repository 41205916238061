import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form'
import Error from 'components/Common/Error';
import Img from 'assets/home_bg2.jpg';
import { LoginForm } from 'components/User/Login';
import { SessionContext } from 'contexts/Session';
import { useApi } from 'hooks/Api'

const Background = styled.div`
  background: #220308;
  width: 100vw;
  height: 100vh;

  :after {
    content: '';
    opacity: 0.24;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    background-image: url(${Img});
    background-size: contain;
    background-position: bottom right;
  }
`;

const Main = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh; 
  z-index: 3;
`;

const IntroText = styled.div`
  line-height: 1.4;
  color: rgba(255, 255, 255);
  font-size: 3em;
  font-weight: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 2em;
`;

export default function() {
  const form = useForm();
  const { login } = useContext(SessionContext);
  const [errorResponse, setErrorResponse] = useState();
  const { handleSubmit, reset } = form;
  const { data, loading, error, sendRequest } = useApi('/auth/login', {
    delayed: true,
    method: 'POST',
    sendToken: false
  });

  const onSubmit = ({ email, password }) => {
    sendRequest({ email, password });
  };

  useEffect(() => {
    if (error) {
      if (error.response) {
        if (401 === error.response.status) {
          reset();
          setErrorResponse('Authentication failed');
        } else {
          setErrorResponse(error.response.statusText);
        }
      } else {
        setErrorResponse('Network error');
      }
    } else if (data) {
      if (data.token) {
        login(data);
      } else {
        setErrorResponse('Application error');
      }
    }
  }, [error, data, login, reset, setErrorResponse]);

  return (
    <>
      <Main>
        <Container
          className="d-flex flex-column justify-content-center"
          style={{ minHeight: '100vh' }}
        >
          <Row className="justify-content-center mb-5">
            <Col className="d-none d-lg-block" lg={7}>
              <IntroText>
                <h4><b>Farm Radio International</b></h4>
                <p className="mb-5">
                  Covid-19<br /> Broadcaster Voice<br />Q&A (Beta)
                </p>
              </IntroText>
            </Col>
            <Col md={8} lg={5}>
              <Card className="border-0 shadow p-4">
                <Card.Body>
                  <h2 className="mb-4 text-center">
                    <span style={{ color: '#858796' }}>
                      <FontAwesomeIcon
                        className="mb-3"
                        icon={faMicrophone}
                        style={{ 
                          fontSize: '64px', 
                          width: '100%',
                        }}
                      />
                    </span>
                    Log in
                  </h2>
                  {errorResponse && (
                    <Error error={errorResponse} />
                  )}
                  <LoginForm
                    {...form} 
                    disabled={loading}
                    onSubmit={handleSubmit(onSubmit)} 
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Main>
      <Background />
    </>
  );
}
