import React, { useContext, useState, useEffect } from 'react';
import { Breadcrumb, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Error from 'components/Common/Error';
import Page from 'components/Dashboard/Page';
import Spinner from 'components/Common/Spinner';
import { SessionContext } from 'contexts/Session';
import { useApi } from 'hooks/Api';

function AudienceTable({ audience }) {
  return audience?.length ? (
    <Table size="sm" bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {audience.map(({ id, name, ...attrs }) => (
          <tr key={id}>
            <td>{id}</td>
            <td>
              <Link to={`/audience/${id}`}>
                {name}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <>
      No audience available
    </>
  );
}

export default function() {
  const { project } = useContext(SessionContext);
  const [errorResponse, setErrorResponse] = useState();
  const {
    data,
    error,
    reload,
  } = useApi(`/audience`);
  const [audience, total] = data ? [data.audience, data.total] : [null, 0];

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [data, error]);

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Audience</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        <h1 className="mb-4">
          Audience
        </h1>
        {errorResponse ? (
          <Error error={errorResponse} />
        ) : audience ? (
          <AudienceTable audience={audience} />
        ) : (
          <Spinner />
        )}
      </Page>
    </>
  );
}
