import List from './List';
import Create from './Create';
import Show from './Show';
//import Results from './Results';
//import Announcements from './Announcements';
//import Surveys from './Surveys';

const components = {
  List,
  Create,
  Show,
//  Results,
//  Announcements,
//  Surveys,
};

export default components;
