import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import api from '../_api';
import State from '../_components/State';
import Transition from '../_components/Transition';
import { SurveyContext } from '../_contexts/Survey';

export default function FlowEditor() {
  const { id } = useParams();
  const {
    survey,
    setSurvey,
    states,
    setStates,
    addState,
    transitions,
    setTransitions,
  } = useContext(SurveyContext);

  const handleDrop = async (props, monitor) => {
    const item = monitor.getItem();
    const delta = monitor.getDifferenceFromInitialOffset();
    let newStates = [...states];

    const left = Math.round(newStates[item.index].left + delta.x);
    const top = Math.round(newStates[item.index].top + delta.y);

    newStates[item.index] = {
      ...newStates[item.index],
      top,
      left,
    };

    setStates(newStates); // Optimistic UI

    const response = await api(`/states/${newStates[item.index].id}`, {
      method: 'PUT',
      data: { state: { top, left } },
    });
  };

  const [collectedProps, drop] = useDrop({
    accept: 'state',
    drop: handleDrop,
  });

  useEffect(() => {
    const fetchSurvey = async () => {
      const response = await api(`/surveys/${id}`, { method: 'GET' });

      if (response) {
        setSurvey(response.data.survey);
        setStates(response.data.survey.states);
        setTransitions(response.data.survey.transitions);
      }
    };

    fetchSurvey();
  }, [id]);

  const handleClick = async () => {
    const { data } = await api('/states', {
      method: 'POST',
      data: {
        state: {
          name: '',
          content: '',
          text: '',
          left: 0,
          top: 0,
          transition: 0,
          feature_id: id,
        },
      },
    });

    addState(data.state);
  };

  if (!survey) {
    return (
      <progress className="progress is-large is-info" max="100">
        15%
      </progress>
    );
  }

  return (
    <div>
      <h1>{survey.name}</h1>
      <div className="flow-container" ref={drop}>
        <div>
          {states.map((state, index) => (
            <State state={state} key={index} index={index} />
          ))}
        </div>
        <svg style={{ width: '100%', height: '100%' }}>
          {transitions.map((transition, index) => (
            <Transition transition={transition} key={index} />
          ))}
        </svg>
      </div>
      <Button disabled={states.length > 0} onClick={handleClick}>
        Add state
      </Button>
    </div>
  );
}
