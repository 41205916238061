import React, { useContext, useState, useEffect } from 'react';
import Error from '../../Common/Error';
import Spinner from '../../Common/Spinner';
import Page from '../../Dashboard/Page';
import { Link, useParams } from 'react-router-dom';
import { Button, Table, Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { SessionContext } from '../../../contexts/Session';
import { useApi } from '../../../hooks/Api';

// TODO: clean up

function CampaignView({ campaign }) {
  return (
    <p>
      {JSON.stringify(campaign)}
    </p>
  )
}

function FeatureTable({ features }) {
  return features?.length ? (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Channel</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {features.map((feature) => (
          <tr key={feature.id}>
            <td>
              <Link to={`/${feature.type.toLowerCase()}s/${feature.id}`}>
                {feature.id}
              </Link>
            </td>
            <td>{feature.name}</td>
            <td>{feature.channel}</td>
            <td>
              <Button size="sm"
                onClick={() => { /* runFeature(feature) */ }}
              >
                Run
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <p>
      No features
    </p>
  );
}

export default function() {
  const { id } = useParams();
  const { project } = useContext(SessionContext);
  const [errorResponse, setErrorResponse] = useState();
  const {
    data,
    error,
  } = useApi(`/projects/${project.id}/campaigns/${id}`);
  const campaign = data ? data.campaign : null;

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [data, error]);

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/project">
          <Breadcrumb.Item>
            Project
          </Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/project/campaigns">
          <Breadcrumb.Item>
            Campaigns
          </Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          {campaign ? campaign.name : '…'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        {errorResponse ? (
          <Error error={errorResponse} />
        ) : campaign ? (
          <>
            <CampaignView campaign={campaign} />
            <h3>Features</h3>
            <FeatureTable features={campaign.features} />
            <h5>Add feature</h5>
            <ul>
              <li>
                <Link
                  className="button is-primary"
                  to={`/project/campaigns/${campaign.id}/announcements/new`}
                >
                  Create new announcement
                </Link>
              </li>
              <li>
                <Link
                  className="button is-primary"
                  to={`/project/campaigns/${campaign.id}/surveys/new`}
                >
                  Create new survey
                </Link>
              </li>
            </ul>
          </>
        ) : (
          <Spinner />
        )}
      </Page>
    </>
  );
}
