import React, { useContext } from 'react';
import styled from 'styled-components';
import { UiContext } from 'contexts/Ui';

const Divider = styled.hr`
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin: 0.7em 0;
`;

const Title = styled.span`
  line-height: 1.3em;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: ${({ isRibbon }) => (isRibbon ? '0.65em' : '0.75em')};
  font-weight: 800;
  color: rgba(255, 255, 255, 0.4);
  text-align: ${({ isRibbon }) => (isRibbon ? 'center' : 'left')};
  display: flex;
  justify-content: ${({ isRibbon }) => (isRibbon ? 'center' : 'left')};
`;

export default function ({ title, children }) {
  const { isMobile, sidebarExpanded } = useContext(UiContext);
  return (
    <>
      {title && (
        <li>
          <Title isRibbon={isMobile || !sidebarExpanded}>{title}</Title>
        </li>
      )}
      {children}
      <li>
        <Divider />
      </li>
    </>
  );
}
