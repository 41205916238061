import { useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { SessionContext } from 'contexts/Session';
import Url from 'helpers/Url';

function useApiImpl(uri, options = {}) {
  const { token, logout } = useContext(SessionContext);

  let { headers, delayed, method, sendToken } = Object.assign({}, {
    delayed: false,
    method: 'GET',
    sendToken: true,
    headers: { 'Content-Type': 'application/json' }
  }, options);

  if (sendToken) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const [{ data, loading, error }, request] = useAxios({
    url: Url.builder(uri),
    method,
    headers,
    mode: 'cors'
  }, {
    manual: delayed
  });

  const sendRequest = data => {
    request({ data });
  }

  const reload = () => {
    request();
  };

  useEffect(() => {
    if (error && error.response && 401 === error.response.status) {
      logout();
    }
  }, [error, logout]);

  const fields = { data, loading, error };

  return delayed 
    ? { ...fields, sendRequest }
    : { ...fields, reload };
}

export function useApi(uri, options) {
  const [isDelayed, setIsDelayed] = useState(false);
  const fields = useApiImpl(uri, options);
  const { data, loading, error, sendRequest, reload } = fields;

  useEffect(() => {
    if (loading) {
      setIsDelayed(true);
      setTimeout(() => {
        setIsDelayed(false);
      }, 200);
    }
  }, [loading]);

  return {
    ...fields,
    data: !isDelayed && data,
    error: !isDelayed && error,
    loading: isDelayed || loading,
  };
}
