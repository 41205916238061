import React, { useState, useEffect } from 'react';
import Error from '../../Common/Error';
import Spinner from '../../Common/Spinner';
import Page from '../../Dashboard/Page';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Table } from 'react-bootstrap';
import { useApi } from '../../../hooks/Api';

function ParticipantsTable({ participants }) {
  return participants?.length ? (
    <Table className="bg-white" size="sm" bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Phone number</th>
        </tr>
      </thead>
      <tbody>
        {participants.map(({ id, name, phone_number, ...attrs }) => (
          <tr key={id}>
            <td>{id}</td>
            <td>
              <Link to={`/project/participants/${id}`}>
                {name}
              </Link>
            </td>
            <td>
              {phone_number}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <>
      No participants 
    </>
  );
}

export default function() {
  const [errorResponse, setErrorResponse] = useState();
  const {
    data,
    error,
  } = useApi(`/participants`);
  const [participants, total] = data ? [data.participants, data.total] : [null, 0];

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [data, error]);

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/project">
          <Breadcrumb.Item>
            Project
          </Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Participants</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        <h1 className="mb-4">
          Participants
        </h1>
        {errorResponse ? (
          <Error error={errorResponse} />
        ) : participants ? (
          <ParticipantsTable participants={participants} />
        ) : (
          <Spinner />
        )}
      </Page>
    </>
  )
}
