import React from 'react';
import {
  Line, ComposedChart, PieChart, Pie, Sector, ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area 
} from 'recharts';

export default function({ data, country }) {

  const proc = Object.keys(data.timeline.cases).map(date => {
    const cases = data.timeline.cases[date];
    const deaths = data.timeline.deaths[date];
    const recovered = data.timeline.recovered[date];
    const active = cases - (deaths + recovered);
    return { date, cases, deaths, recovered, active };
  });

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        data={proc}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Area name="Active" dataKey="active" stackId="a" stroke="#82ca9d" fill="#82ca9d" />
        <Area name="Recovered" dataKey="recovered" stackId="a" stroke="#8884d8" fill="#8884d8" />
        <Area name="Deaths" dataKey="deaths" stackId="a" stroke="#d88488" fill="#d88488" />
        <Line name="Confirmed" dataKey="cases" stackId="b" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
