import React, { useContext } from 'react';
import styled from 'styled-components';
import Component from 'components/Dashboard/Sidebar/Menu/Item/Component';
import { UiContext } from 'contexts/Ui';

const Item = styled.li`
  display: flex;
  align-items: center;

  a {
    padding: ${({ isRibbon }) => (isRibbon ? '0' : '0.6em 0')};
    margin-top: ${({ isRibbon }) => (isRibbon ? '-40px' : '0')};
    padding-top: ${({ isRibbon }) => (isRibbon ? '40px' : '0.6em')};
  }
`;

export default function ({ icon, children, ...props }) {
  const {
    sidebarMenu: { closeAll },
    isMobile,
    sidebarExpanded,
  } = useContext(UiContext);
  return (
    <Item isRibbon={isMobile || !sidebarExpanded} {...props}>
      <Component icon={icon} onClick={closeAll}>
        {children}
      </Component>
    </Item>
  );
}
