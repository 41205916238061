import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Component from 'components/Dashboard/Sidebar/Menu/Item/Component';
import { UiContext } from 'contexts/Ui';

const Collapsible = styled.div`
  background: #ffffff;
  overflow: hidden;
  margin: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  padding: 0.8em 0.5em;
  border-radius: 4px;
  min-width: ${({ isRibbon }) => (isRibbon ? '10.5rem' : 'inherit')};
  position: ${({ isRibbon }) => (isRibbon ? 'absolute' : 'relative')};
  left: ${({ isRibbon }) => (isRibbon ? '6.5rem' : '0')};
  margin-top: ${({ isRibbon }) => (isRibbon ? '-70px' : '0')};
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.25);
  z-index: 20;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default function ({ title, icon, menuKey, children }) {
  const {
    sidebarMenu: { toggleExpanded, isExpanded },
    isMobile,
    sidebarExpanded,
  } = useContext(UiContext);

  const iconStyle =
    !isMobile && sidebarExpanded
      ? {}
      : {
          left: '74px',
          marginTop: '-20px',
          position: 'absolute',
        };

  const anim =
    !isMobile && sidebarExpanded ? 'animated--fade-in' : 'animated--grow-in';

  return (
    <li>
      <div style={{ position: 'relative' }}>
        <Component
          icon={icon}
          title={title}
          onClick={() => {
            toggleExpanded(menuKey);
          }}
        >
          <FontAwesomeIcon
            icon={isExpanded(menuKey) ? faAngleDown : faAngleRight}
            style={iconStyle}
          />
        </Component>
        {children && (
          <Collapsible
            className={isExpanded(menuKey) ? anim : ''}
            isRibbon={isMobile || !sidebarExpanded}
            open={isExpanded(menuKey)}
          >
            <List>{children}</List>
          </Collapsible>
        )}
      </div>
    </li>
  );
}
