import React, { useContext, useState, useEffect } from 'react';
import { Dropdown, Alert, Form, Textarea, Modal, ButtonGroup, Button, Breadcrumb, Row, Col, Table, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { faPlay, faTag, faTimes, faReply } from '@fortawesome/free-solid-svg-icons';
import Page from 'components/Dashboard/Page';
import Error from 'components/Common/Error';
import Spinner from 'components/Common/Spinner';
import { SessionContext } from 'contexts/Session';
import { useApi } from 'hooks/Api';

function ConfirmDeleteModal(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Response
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control as="textarea" rows="3" />
      </Modal.Body>
      <Modal.Footer>
        <Button block onClick={() => {}}>Send response</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ResponseModal({ sent, onSubmit, ...props }) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Response
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="primary">
          Your response will be sent as SMS to the questioner. Keep the answer as short as possible.
        </Alert>
        <Alert variant="danger">
          Only rely on credible sources and <b>always follow recommendations and guidelines from WHO and national health authorities.</b>
        </Alert>
        <Form.Group>
          <Form.Control 
            disabled={sent}
            as="textarea" 
            rows="4" 
          />
        </Form.Group>
        <Form.Group>
          <Form.Check type="checkbox" label="Close question" />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          block 
          disabled={sent} 
          onClick={onSubmit}
        >
          Send response
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function QuestionsTable({ questions, onRespondTo, onDelete }) {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Audio</th>
            <th>Phone number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, i) => (
            <tr key={i}>
              <td style={{ verticalAlign: 'middle' }}>
                <audio controls>
                  <source src={question.audio} type="audio/ogg" />
                  <source src={question.audio} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                {question?.participant?.phone_number}
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Button 
                  style={{ marginRight: '0.2em' }} 
                  onClick={() => {
                    onRespondTo(question);
                  }} 
                  size="md">
                  <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faReply} />
                  Respond
                </Button>
                {/*
                <Button style={{ marginRight: '0.2em' }} size="md">
                  <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faTag} />
                  Tag
                </Button>
                */}
                <Button 
                  variant="primary" 
                  size="md"
                  onClick={() => {
                    onDelete(question);
                  }}
                >
                  <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faTimes} />
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default function() {
  const PAGE_SIZE = 10;
  const offset = 0 * PAGE_SIZE;
  const { project } = useContext(SessionContext);
  const [errorResponse, setErrorResponse] = useState();
  const {
    data,
    error,
  } = useApi(`/projects/${project.id}/tasks/questions?offset=${offset}&limit=${PAGE_SIZE}`);

  useEffect(() => {
    if (error) {
      if (error.response) {
        setErrorResponse(error.response.statusText);
      } else {
        setErrorResponse('Network error');
      }
    }
  }, [data, error]);

  const [responseModal, setResponseModal] = React.useState(null);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = React.useState(false);

  const questions = [
    {},
    {},
    {},
    {},
    {}
  ];

  const handleRespondTo = question => {
    setResponseModal({
      sent: false
    });
  };

  const handleDelete = question => {
    setConfirmDeleteVisible(true);
  };

  return (
    <>
      <ResponseModal
        show={!!responseModal}
        onHide={() => { setResponseModal(null); }}
        onSubmit={() => { setResponseModal({ ...responseModal, sent: true }); }}
        {...responseModal}
      />
      <ConfirmDeleteModal
        show={confirmDeleteVisible}
        onHide={() => { setConfirmDeleteVisible(false); }}
      />
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Questions</Breadcrumb.Item>
      </Breadcrumb>
      <Page>
        <h1 className="mb-4">
          Questions
        </h1>
        <Form>
          <Form.Group>
            <Form.Control as="select" custom>
              <option>Ghana</option>
              <option>Kenya</option>
              <option>Nigeria</option>
              <option>Senegal</option>
              <option>Tanzania</option>
              <option>Uganda</option>
            </Form.Control>
          </Form.Group>
        </Form>
        {errorResponse ? (
          <Error error={errorResponse} />
        ) : data?.questions ? (
          <QuestionsTable 
            questions={data.questions} 
            onRespondTo={handleRespondTo}
            onDelete={handleDelete}
          />
        ) : (
          <Spinner />
        )}
      </Page>
    </>
  );
}
