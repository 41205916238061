import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';
import React from 'react';
import Routes from 'Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { SessionContextProvider } from 'contexts/Session';
import { UiContextProvider } from 'contexts/Ui';
import ToastArea from 'components/ToastArea';
//
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

export default function() {
  return (
    <Router>
      <UiContextProvider>
        <SessionContextProvider>
          <DndProvider backend={Backend}>
            <div>
              <Routes />
              <ToastArea />
            </div>
          </DndProvider>
        </SessionContextProvider>
      </UiContextProvider>
    </Router>
  );
}
