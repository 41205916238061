import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Dashboard from 'components/Dashboard';
import SelectProject from 'components/Projects/Select';
import { SessionContext } from 'contexts/Session';

export function PrivateRoute({ children, ...props }) {
  const { isAuthenticated } = useContext(SessionContext);

  return (
    <Route
      {...props}
      render={({ location }) =>
        isAuthenticated() ? children : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export function DashboardRoute({ children, ...props }) {
  const { hasProject } = useContext(SessionContext);

  return (
    <PrivateRoute {...props}>
      {hasProject() ? (
        <Dashboard>{children}</Dashboard>
      ) : (
        <SelectProject />
      )}
    </PrivateRoute>
  );
}
