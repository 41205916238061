import List from './List';
import Create from './Create';
import Show from './Show';

const components = {
  List,
  Create,
  Show,
};

export default components;
