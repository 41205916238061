import { API_URL } from 'config';

function builder(uri) {
  let url = new URL(uri.match(/^http/) ? uri : `${API_URL}${uri}`);
  return url.toString();
}

const api = {
  builder
};

export default api;
