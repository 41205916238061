import React from 'react';
import { Link } from 'react-router-dom';
import { useApi } from '../_api';

export default function SurveyList() {
  const { data } = useApi('/surveys');
  const surveys = data ? data.surveys : [];

  if (!surveys) {
    return (
      <progress className="progress is-large is-info" max="100">
        15%
      </progress>
    );
  }

  return (
    <div>
      <h1>Surveys</h1>
      <ul>
        {surveys.map((survey, index) => (
          <li key={index}>
            <Link to={`/surveys/${survey.id}`}>{survey.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
