import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import api, { useApi } from '../../../../_api';

export default function () {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [participantIds, setParticipantIds] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const { data } = useApi('/participants');
  const participants = data ? data.participants : [];

  const handleSubmit = async e => {
    e.preventDefault();

    if (name && message && participantIds.length > 0) {
      let ids = [];

      for (let p of participantIds) {
        ids.push(p.value);
      }

      const response = await api('/announcements', {
        method: 'POST',
        body: JSON.stringify({
          name,
          message,
          channel: 'twilio_sms',
          campaign_id: id,
          flow_id: 1, // Hard coded for now
          participant_ids: ids.join(','),
        }),
      });

      if (response.data.announcement) {
        setIsCompleted(true);
      }
    }
  };

  if (isCompleted) {
    return <Redirect to={`/campaigns/${id}`} />;
  }

  if (!participants) {
    return (
      <progress className="progress is-large is-info" max="100">
        15%
      </progress>
    );
  }

  return (
    <div>
      <h1>Announcement</h1>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Text input"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Channel</label>
          <div className="control">
            <div className="select">
              <select>
                <option>SMS</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">Message</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Text input"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Participants</label>
          <div className="control">
            <div
              className="select is-multiple"
              value={participantIds}
              onChange={e => setParticipantIds(e.target.selectedOptions)}
            >
              <select multiple>
                {participants.map(participant => (
                  <option key={participant.id} value={participant.id}>
                    {participant.name} - {participant.phone_number}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <button type="submit" className="button is-primary">
            Create
          </button>
        </div>
      </form>
    </div>
  );
}
