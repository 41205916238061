import Questions from './Questions/index';
import Upload from './Questions/Upload';

Questions.Upload = Upload;

const components = {
  Questions,
};

export default components;
