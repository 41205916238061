import React, { useContext } from 'react';
import Menu from '../Dashboard/Sidebar/Menu';
import { UiContext } from '../../contexts/Ui';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import {
  faComments,
  faMap,
  faQuestionCircle,
  faDatabase,
  faUserCheck,
  faPhone,
  faCloudUploadAlt,
  faMobileAlt,
  faChartPie,
  faUsers,
  faTable,
} from '@fortawesome/free-solid-svg-icons';

export default function () {
  const { isMobile, sidebarExpanded } = useContext(UiContext);

  const badgeStyle =
    isMobile || !sidebarExpanded
      ? {
          position: 'absolute',
          marginLeft: '-13px',
          marginTop: '-40px',
          fontSize: '0.85em',
        }
      : {};

// ORIGINAL MENU
//
//    <>
//      <Menu.ItemGroup>
//        <Menu.Item icon={faMap}>
//          <Link to="/">Overview</Link>
//        </Menu.Item>
//      </Menu.ItemGroup>
//      <Menu.ItemGroup title="Interactivity">
//        <Menu.Submenu menuKey="project" title="Project" icon={faComments}>
//          <Menu.SubmenuTitle>Campaigns</Menu.SubmenuTitle>
//          <Menu.SubmenuItem>
//            <Link to="/project/campaigns">Campaigns</Link>
//          </Menu.SubmenuItem>
//          <Menu.SubmenuItem>
//            <Link to="/project/campaigns/new">New campaign</Link>
//          </Menu.SubmenuItem>
//          <Menu.SubmenuItem>
//            <Link to="/project/participants">Participants</Link>
//          </Menu.SubmenuItem>
//          <Menu.SubmenuDivider />
//          <Menu.SubmenuTitle>Countries</Menu.SubmenuTitle>
//          <Menu.SubmenuItem>
//            <Link to="/project/countries">Manage countries</Link>
//          </Menu.SubmenuItem>
//        </Menu.Submenu>
//        <Menu.Item icon={faPhone}>
//          <Link to="/phone_lines">Numbers</Link>
//        </Menu.Item>
//        <Menu.Item icon={faUsers}>
//          <Link to="/audience">Audience</Link>
//        </Menu.Item>
//        <Menu.Submenu menuKey="content" title="Content" icon={faDatabase}>
//          <Menu.SubmenuTitle>Audio</Menu.SubmenuTitle>
//          <Menu.SubmenuItem>
//            <Link to="/content/audio">Audio archive</Link>
//          </Menu.SubmenuItem>
//          <Menu.SubmenuItem>
//            <Link to="/content/audio/upload">Upload</Link>
//          </Menu.SubmenuItem>
//          <Menu.SubmenuDivider />
//          <Menu.SubmenuTitle>Text</Menu.SubmenuTitle>
//          <Menu.SubmenuItem>
//            <Link to="/content/messages">Text message archive</Link>
//          </Menu.SubmenuItem>
//          <Menu.SubmenuItem>
//            <Link to="/content/messages/new">Create message</Link>
//          </Menu.SubmenuItem>
//        </Menu.Submenu>
//      </Menu.ItemGroup>
//      <Menu.ItemGroup title="Tasks">
//        <Menu.Item icon={faUserCheck}>
//          <Link to="/tasks/questions">Questions</Link>
//          <div style={{ position: 'relative' }}>
//            <Badge variant="danger" pill style={badgeStyle}>
//              99
//            </Badge>
//          </div>
//        </Menu.Item>
//      </Menu.ItemGroup>
//      <Menu.ItemGroup title="Insights">
//        <Menu.Submenu menuKey="results" title="Results" icon={faChartPie}>
//          <Menu.SubmenuTitle>Polls</Menu.SubmenuTitle>
//          <Menu.SubmenuItem>
//            <Link to="/results/polls">Responses</Link>
//          </Menu.SubmenuItem>
//          <Menu.SubmenuItem>
//            <Link to="/results/polls/analytics">Analytics</Link>
//          </Menu.SubmenuItem>
//          <Menu.SubmenuDivider />
//          <Menu.SubmenuTitle>Q&A</Menu.SubmenuTitle>
//          <Menu.SubmenuItem>
//            <Link to="/results/answers/analytics">Analytics</Link>
//          </Menu.SubmenuItem>
//        </Menu.Submenu>
//      </Menu.ItemGroup>
//      <Menu.ItemGroup title="Discover">
//        <Menu.Item icon={faMobileAlt}>
//          <Link to="/apps">Apps</Link>
//        </Menu.Item>
//        <Menu.Item icon={faQuestionCircle}>
//          <Link to="/help">Help</Link>
//        </Menu.Item>
//      </Menu.ItemGroup>
//      <Menu.ToggleButton />
//    </>

  return (
    <>
      <Menu.ItemGroup>
        <Menu.Item icon={faMap}>
          <Link to="/">Overview</Link>
        </Menu.Item>
      </Menu.ItemGroup>
      {/*
      <Menu.ItemGroup title="Interactivity">
        <Menu.Submenu menuKey="project" title="Project" icon={faComments}>
          <Menu.SubmenuTitle>Campaigns</Menu.SubmenuTitle>
          <Menu.SubmenuItem>
            <Link to="/project/campaigns">Campaigns</Link>
          </Menu.SubmenuItem>
          <Menu.SubmenuItem>
            <Link to="/project/campaigns/new">New campaign</Link>
          </Menu.SubmenuItem>
          <Menu.SubmenuItem>
            <Link to="/project/participants">Participants</Link>
          </Menu.SubmenuItem>
          <Menu.SubmenuDivider />
          <Menu.SubmenuTitle>Countries</Menu.SubmenuTitle>
          <Menu.SubmenuItem>
            <Link to="/project/countries">Manage countries</Link>
          </Menu.SubmenuItem>
        </Menu.Submenu>
        <Menu.Item icon={faPhone}>
          <Link to="/phone_lines">Numbers</Link>
        </Menu.Item>
        <Menu.Item icon={faUsers}>
          <Link to="/audience">Audience</Link>
        </Menu.Item>
        <Menu.Submenu menuKey="content" title="Content" icon={faDatabase}>
          <Menu.SubmenuTitle>Audio</Menu.SubmenuTitle>
          <Menu.SubmenuItem>
            <Link to="/content/audio">Audio archive</Link>
          </Menu.SubmenuItem>
          <Menu.SubmenuItem>
            <Link to="/content/audio/upload">Upload</Link>
          </Menu.SubmenuItem>
          <Menu.SubmenuDivider />
          <Menu.SubmenuTitle>Text</Menu.SubmenuTitle>
          <Menu.SubmenuItem>
            <Link to="/content/messages">Text message archive</Link>
          </Menu.SubmenuItem>
          <Menu.SubmenuItem>
            <Link to="/content/messages/new">Create message</Link>
          </Menu.SubmenuItem>
        </Menu.Submenu>
      </Menu.ItemGroup>
      */}
      <Menu.ItemGroup title="Campaign response">
        <Menu.Item icon={faUserCheck}>
          <Link to="/tasks/questions">Questions</Link>
          <div style={{ position: 'relative' }}>
            <Badge variant="danger" pill style={badgeStyle}>
              99
            </Badge>
          </div>
        </Menu.Item>
        <Menu.Item icon={faCloudUploadAlt}>
          <Link to="/tasks/questions/upload">Upload question</Link>
        </Menu.Item>
        <Menu.Item icon={faTable}>
          <Link to="/results">Results</Link>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Covid-19 stats">
        <Menu.Submenu menuKey="results" title="Charts" icon={faChartPie}>
          {/*
          <Menu.SubmenuTitle>Polls</Menu.SubmenuTitle>
          */}
          <Menu.SubmenuItem>
            <Link to="/results/polls">Per country</Link>
          </Menu.SubmenuItem>
          <Menu.SubmenuItem>
            <Link to="/results/polls/analytics">Africa</Link>
          </Menu.SubmenuItem>
          <Menu.SubmenuItem>
            <Link to="/results/polls/analytics">Worldwide</Link>
          </Menu.SubmenuItem>
          {/*
          <Menu.SubmenuDivider />
          <Menu.SubmenuTitle>Q&A</Menu.SubmenuTitle>
          <Menu.SubmenuItem>
            <Link to="/results/answers/analytics">Analytics</Link>
          </Menu.SubmenuItem>
          */}
        </Menu.Submenu>
      </Menu.ItemGroup>
      <Menu.ToggleButton />
    </>
  );
}
