import React from 'react';
import { Pagination } from 'react-bootstrap';

export default function ({ current, total, onSelectPage }) {
  const page = Number(current);
  if (total > 1) {
    const items = Array.from(Array(total).keys()).map(n => {
      const number = n + 1;
      return (
        <Pagination.Item
          onClick={() => {
            onSelectPage(number);
          }}
          key={number}
          active={number === page}
        >
          {number}
        </Pagination.Item>
      );
    });
    return (
      <Pagination size="sm">
        <Pagination.Prev
          disabled={page === 1}
          onClick={() => {
            onSelectPage(page - 1);
          }}
        >
          Prev
        </Pagination.Prev>
        {items}
        <Pagination.Next
          disabled={page >= total}
          onClick={() => {
            onSelectPage(page + 1);
          }}
        >
          Next
        </Pagination.Next>
      </Pagination>
    );
  } else {
    return <></>;
  }
}
