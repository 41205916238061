import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDrag } from 'react-dnd';
import { SurveyContext } from './../_contexts/Survey';
import api from './../_api';
import StateModal from './StateModal';

export default function State({ state, index }) {
  const [show, setShow] = useState(false);
  const {
    updateState,
    removeState,
    addState,
    transitions,
    setTransitions
  } = useContext(SurveyContext);

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'state', index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const createState = async state => {
    const response = await api('/states', {
      method: 'POST',
      data: { state }
    });

    if (response.data.state) {
      return response.data.state;
    }

    return;
  };

  const addYes = async () => {
    const nextState = await createState({
      name: 'Yes',
      content: '',
      text: '',
      left: 0,
      top: state.top + 300,
      transition: 0,
      feature_id: state.feature_id
    });

    addState(nextState);

    const response = await api('/transitions', {
      method: 'POST',
      data: {
        transition: {
          name: 'Yes',
          content: { digits: 1 },
          previous_state_id: state.id,
          next_state_id: nextState.id,
          feature_id: state.feature_id
        }
      }
    });

    setTransitions([...transitions, response.data.transition]);
  };

  const addNo = async () => {
    const nextState = await createState({
      name: 'No',
      content: '',
      text: '',
      left: state.left + 300,
      top: state.top + 300,
      transition: 0,
      feature_id: state.feature_id
    });

    addState(nextState);

    const response = await api('/transitions', {
      method: 'POST',
      data: {
        transition: {
          name: 'Yes',
          content: { digits: 2 },
          previous_state_id: state.id,
          next_state_id: nextState.id,
          feature_id: state.feature_id
        }
      }
    });

    setTransitions([...transitions, response.data.transition]);
  };

  return (
    <div>
      <Card
        className="state"
        ref={drag}
        style={{
          opacity: isDragging ? 0 : 1,
          left: state.left,
          top: state.top
        }}
      >
        <Card.Body>
          <Card.Title>
            {state.name}{' '}
            <Button variant="link" onClick={() => setShow(true)}>
              Edit
            </Button>
          </Card.Title>
        </Card.Body>
        <Card.Body>
          {state.transition === 2 && (
            <Button variant="secondary" onClick={addYes}>
              Add Yes
            </Button>
          )}
          {state.transition === 2 && (
            <Button variant="secondary" onClick={addNo}>
              Add No
            </Button>
          )}
        </Card.Body>
      </Card>
      <StateModal state={state} index={index} show={show} setShow={setShow} />
    </div>
  );
}
