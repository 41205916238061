import React from 'react';
import Header from './Header';
import Navs from '../Navs';
import Sidebar from './Sidebar';
import styled from 'styled-components';

const ContentArea = styled.div`
  flex: 1;
  background-color: #fafafa;
  min-height: calc(100vh - 4.375rem);
  padding: 1.4em;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageArea = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function ({ children }) {
  return (
    <Wrapper>
      <Header>
        <Navs.Header />
      </Header>
      <PageArea>
        <Sidebar>
          <Navs.Sidebar />
        </Sidebar>
        <ContentArea>
          {children}
        </ContentArea>
      </PageArea>
    </Wrapper>
  );
}
