import React, { useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { UiContext } from 'contexts/Ui';

const Container = styled.div`
  width: ${({ isMobile, isExpanded }) =>
    isMobile || !isExpanded ? '6.5rem' : '14rem'};
  display: ${({ isMobile, isExpanded }) =>
    isMobile && !isExpanded ? 'none' : 'block'};
  min-height: calc(100vh - 4.375rem);
  background: #2c2d36;
  color: #ffffff;
  padding: 1em 0.9em;

  a:focus,
  button:focus {
    outline: none;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default function ({ children, ...props }) {
  const node = useRef();
  const {
    sidebarMenu: { closeAll },
    isMobile,
    sidebarExpanded,
  } = useContext(UiContext);

  useEffect(() => {
    const handleClick = e => {
      if (node.current.contains(e.target)) {
        return;
      }
      closeAll();
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [closeAll]);

  return (
    <Container
      className="noselect"
      isMobile={isMobile}
      isExpanded={sidebarExpanded}
    >
      <List ref={node} {...props}>
        {children}
      </List>
    </Container>
  );
}
