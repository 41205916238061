import Login from './Login';
import Register from './Register';
import Profile from './Profile';

const components = {
  Login,
  Register,
  Profile,
};

export default components;
