import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { UiContext } from 'contexts/Ui';

const Container = styled.div`
  display: flex;
  justify-content: center;

  div.rounded-circle {
    background-color: rgba(255, 255, 255, 0.2);
  }

  div.rounded-circle:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export default function () {
  const { toggleSidebar, sidebarExpanded, isMobile } = useContext(UiContext);
  if (isMobile) {
    return <></>;
  } else {
    return (
      <Container>
        <div
          className="rounded-circle border-0"
          onClick={toggleSidebar}
          style={{
            width: '2.5rem',
            height: '2.5rem',
            cursor: 'pointer',
            marginBottom: '1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={sidebarExpanded ? faAngleLeft : faAngleRight}
            style={{ color: 'rgba(255, 255, 255, 0.8)' }}
          />
        </div>
      </Container>
    );
  }
}
