import Audience from 'components/Audience';
import Campaigns from 'components/Projects/Campaigns';
import Content from 'components/Content';
import Dashboard from 'components/Dashboard';
import Error404 from 'components/Error404';
import Landing from 'components/Landing';
import Projects from 'components/Projects';
import Vis from 'components/Vis';
import React, { useContext } from 'react';
import SelectProject from 'components/Projects/Select';
import Tasks from 'components/Tasks';
import User from 'components/User';
import { DashboardRoute } from 'components/Router';
import { Redirect } from 'react-router-dom';
import { SessionContext } from 'contexts/Session';
import { Switch, Route } from 'react-router-dom';

// TODO: fix
import FlowEditor from './_routes/FlowEditor';
import SurveyDetail from './_routes/SurveyDetail';
import SurveyList from './_routes/SurveyList';
import CreateSurvey from './_components/Projects/Campaigns/Surveys/Create';
import CreateAnnouncement from './_components/Projects/Campaigns/Announcements/Create';
import { SurveyContextProvider } from './_contexts/Survey';

function Todo() {
  return (
    <>
      Todo
    </>
  );
}

export default function() {
  const { isAuthenticated, hasProject } = useContext(SessionContext);
  const authenticated = isAuthenticated();

  return (
    <Switch>
      <Route exact path="/results">
        <div style={{ margin: '2em' }}>
          <Vis />
        </div>
      </Route>
      <Route exact path="/login">
        {authenticated && <Redirect to="/" />}
        <User.Login />
      </Route>
      <Route exact path="/register">
        {authenticated && <Redirect to="/" />}
        <User.Register />
      </Route>
      <Route exact path="/">
        {authenticated && !hasProject() ? (
          <SelectProject />
        ) : !authenticated ? (
          <Landing />
        ) : (
          <Dashboard>
            <Vis />
          </Dashboard>
        )}
      </Route>
      <DashboardRoute exact path="/audience">
        <Audience.List />
      </DashboardRoute>
      <DashboardRoute exact path="/projects/new">
        <Projects.Create />
      </DashboardRoute>
      <DashboardRoute exact path="/projects">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/project/settings">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/project/campaigns/new">
        <Campaigns.Create />
      </DashboardRoute>
      <DashboardRoute exact path="/project/campaigns/:id/announcements/new">
        <CreateAnnouncement />
      </DashboardRoute>
      <DashboardRoute exact path="/project/campaigns/:id/surveys/new">
        <CreateSurvey />
      </DashboardRoute>
      <DashboardRoute exact path="/project/campaigns/:id">
        <Campaigns.Show />
      </DashboardRoute>
      <DashboardRoute exact path="/project/campaigns/(page)?/:page?">
        <Campaigns.List />
      </DashboardRoute>
      <DashboardRoute exact path="/project/campaigns/:id/results">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/project/participants/:id">
        <Projects.Participants.Show />
      </DashboardRoute>
      <DashboardRoute exact path="/project/participants/new">
        <Projects.Participants.Create />
      </DashboardRoute>
      <DashboardRoute exact path="/project/participants">
        <Projects.Participants.List />
      </DashboardRoute>
      <DashboardRoute exact path="/project/countries">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/project">
        <Projects.Current />
      </DashboardRoute>
      <DashboardRoute exact path="/tasks/questions/upload">
        <Tasks.Questions.Upload />
      </DashboardRoute>
      <DashboardRoute exact path="/tasks/questions">
        <Tasks.Questions />
      </DashboardRoute>
      <DashboardRoute exact path="/content/audio">
        <Content.Audio.Archive />
      </DashboardRoute>
      <DashboardRoute exact path="/content/audio/upload">
        <Content.Audio.Upload />
      </DashboardRoute>
      <DashboardRoute exact path="/content/messages">
        <Content.Text.Archive />
      </DashboardRoute>
      <DashboardRoute exact path="/content/messages/new">
        <Content.Text.Create />
      </DashboardRoute>
      <DashboardRoute exact path="/results/polls">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/results/polls/analytics">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/results/answers/analytics">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/apps">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/help">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/notifications">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/phone_lines">
        <Todo />
      </DashboardRoute>
      <DashboardRoute exact path="/user/profile">
        <User.Profile />
      </DashboardRoute>
      {/* TODO: Work on below routes =====> */}
      <DashboardRoute exact path="/surveys/:id/flow_editor">
        <SurveyContextProvider>
          <FlowEditor />
        </SurveyContextProvider>
      </DashboardRoute>
      <DashboardRoute exact path="/surveys/:id">
        <SurveyContextProvider>
          <SurveyDetail />
        </SurveyContextProvider>
      </DashboardRoute>
      <DashboardRoute exact path="/surveys">
        <SurveyList />
      </DashboardRoute>
      {/* TODO: <==== up to here */}
      <Route path="*">
        {authenticated && hasProject() ? (
          <Dashboard>
            <Error404 />
          </Dashboard>
        ) : authenticated ? (
          <SelectProject />
        ) : (
          <Error404 />
        )}
      </Route>
    </Switch>
  );
}
